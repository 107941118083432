@font-face {
  font-family: "ArabicRegular";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url("./assets/fonts/Tajawal-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "ArabicLight";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url("./assets/fonts/Tajawal-Light.ttf") format("truetype");
}

@font-face {
  font-family: "ArabicMedium";
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url("./assets/fonts/Tajawal-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "ArabicBold";
  font-style: normal;
  font-weight: bolder;
  font-display: swap;
  src: url("./assets/fonts/Tajawal-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "EnglishBold";
  font-style: normal;
  font-weight: bolder;
  font-display: swap;
  src: url("./assets/fonts/BeVietnamPro-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "EnglishMedium";
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url("./assets/fonts/BeVietnamPro-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "EnglishRegular";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url("./assets/fonts/BeVietnamPro-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "EnglishLight";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url("./assets/fonts/BeVietnamPro-Light.ttf") format("truetype");
}
