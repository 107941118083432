.footer {
  background-color: white;
  text-align: center;
  width: 100%;
  padding: 2vw;
  margin-top: auto;
}

.footerContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}

@media (min-width: 360px) and (max-width: 414px) {
  .footerContainer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 601px) and (max-width: 1280px) {
  .footerContainer {
    flex-direction: row;
  }
}

@media (min-width: 1280px) and (max-width: 1920px) {
  .footerContainer {
    flex-direction: row;
  }
}
